import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import {
  AssignmentStatus,
  CohortClassStatus,
  CohortUserRoles,
} from '@/shared/enums'
import { CACHE_TIME } from '@/shared/constants'
import { RootState } from '@/shared/app/store'
import {
  ATTENDANCE_STATUS,
  COHORT_TYPE,
  CohortProduct,
  SUBSCRIPTIONS_TYPES,
} from '@/shared/types'

type GetCohortCoursesTransformResponse = {
  cohorts: {
    type: string
    _products_cohorts: {
      product: {
        _product_courses: {
          course: {
            id: string
            name: string
            image: string
            isAsync: boolean
            classes: {
              cohort_classes: {
                status: CohortClassStatus
                date: Date
              }[]
            }[]
          }
        }[]
      }
    }[]
  }[]
  subscriptions: {
    id: string
    status: SUBSCRIPTIONS_TYPES
  }[]
}

type GetCohortCoursesFirstClassesResponse = {
  cohorts: {
    _products_cohorts: {
      product: {
        _product_courses: {
          course: {
            id: string
            isAsync: boolean
            classes: {
              cohort_classes: {
                date: Date
              }[]
            }[]
          }
        }[]
      }
    }[]
  }[]
}

type GetCohortCoursesFirstClassesTransformResponse = {
  _products_cohorts: {
    product: {
      _product_courses: {
        course: {
          id: string
          classes: {
            cohort_classes: {
              date: Date
            }[]
          }[]
        }
      }[]
    }
  }[]
}

export type GetCohortClassesByCourseResponse = {
  cohort_classes: {
    id: string
    status: CohortClassStatus
    date: Date
    isAsync: boolean
    student_attendances: { status: ATTENDANCE_STATUS }[]
    cohort: {
      type: COHORT_TYPE
    }
    class: {
      id: string
      name: string
      course: {
        isAsync: boolean
      }
      exercises: {
        id: string
        name: string
        users_assignments: {
          id: string
          status: AssignmentStatus
        }[]
      }[]
    }
  }[]
}

type GetCohortNextClassResponse = {
  cohort_classes: {
    id: string
    class: {
      name: string
      description: string
    }
  }[]
}

type GetCohortNextClassTransformResponse = {
  id: string
  class: {
    name: string
    description: string
  }
}

type GetCohortNextAssignmentByStudentResponse = {
  users_assignments: {
    id: string
    exercise: {
      id: string
      name: string
      class: {
        course: {
          id: string
          name: string
        }
        cohort_classes: {
          date: Date
        }[]
      }
    }
  }[]
}

type GetCohortNextAssignmentByStudentTransformResponse = {
  id: string
  exercise: {
    id: string
    name: string
    class: {
      course: {
        id: string
        name: string
      }
      cohort_classes: {
        date: Date
      }[]
    }
  }
}

type GetAmountOfSubmittedAssignmentsResponse = {
  users_assignments: { id: string }[]
  users_assignments_aggregate: {
    aggregate: {
      count: number
    }
  }
}

type GetAmountOfSubmittedAssignmentsTransformResponse = {
  total: number
  submitted: number
}

type GetCohortAnnouncementResponse = {
  cohorts: {
    announcements: {
      id: string
      title: string
      content: string
      url: string
    }[]
  }[]
}

type GetCohortAnnouncementTransformResponse = {
  id: string
  title: string
  content: string
  url: string
}

type GetCohortZoomIdResponse = {
  cohorts: {
    zoomId: string
  }[]
}

type GetCohortZoomIdTransformResponse = {
  zoomId: string
}

type GetCohortUserRoleResponse = {
  cohort_integrants: [
    {
      role: CohortUserRoles
    },
  ]
}

type GetCohortUserRoleTransformResponse = {
  role: CohortUserRoles
}

type GetStudentSubscriptionResponse = {
  subscriptions: {
    status: SUBSCRIPTIONS_TYPES
  }[]
  duos: {
    subscription: {
      status: SUBSCRIPTIONS_TYPES
    }
  }[]
}

type GetStudentSubscriptionTransformResponse =
  | {
      status: SUBSCRIPTIONS_TYPES
    }
  | undefined

type GetCohortResponse = {
  cohorts: CohortProduct[]
}

type GetCohortTransformResponse = CohortProduct | undefined

type GetCohortClassByClassIDResponse = {
  cohort_classes: [
    {
      summary: string
      vimeoId: string
      description: string
      student_attendances: {
        status: ATTENDANCE_STATUS
        studentId: string
      }[]
      class: {
        challenge: string
        resources: string[]
        name: string
        description?: string
        courseId: string
        course: {
          isAsync: boolean
        }
        exercises: {
          users_assignments: {
            id: string
          }[]
        }[]
      }
      cohort: {
        type: COHORT_TYPE
      }
    },
  ]
}

type GetCohortClassByClassIDTransformResponse = {
  summary: string
  vimeoId: string
  challenge: string
  resources: string[]
  assignmentId: string
  className: string
  courseId: string
  courseIsAsync: boolean
  attendance: ATTENDANCE_STATUS | null
  description?: string
  cohortType: COHORT_TYPE
}

export const cohortApi = createApi({
  reducerPath: 'cohortApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  tagTypes: ['Class', 'Assignment'],
  endpoints: builder => ({
    getCohortCourses: builder.query<
      GetCohortCoursesTransformResponse,
      { code: string; studentId: string }
    >({
      query: ({ code, studentId }) => ({
        document: gql`
          query CohortCourses(
            $code: String
            $studentId: String
            $_eq: String = ""
          ) {
            cohorts(where: { code: { _eq: $code } }) {
              type
              _products_cohorts(
                where: {
                  product: { cohort_integrants: { id: { _eq: $studentId } } }
                }
              ) {
                product {
                  _product_courses(order_by: { course: { order: asc } }) {
                    course {
                      id
                      name
                      image
                      isAsync
                      classes(limit: 1, order_by: { order: desc }) {
                        cohort_classes(
                          where: { cohort: { code: { _eq: $code } } }
                        ) {
                          status
                          date
                        }
                      }
                    }
                  }
                }
              }
            }
            subscriptions(where: { studentId: { _eq: $studentId } }) {
              id
              status
            }
          }
        `,
        variables: {
          code,
          studentId,
        },
      }),
    }),
    getCohortCoursesFirstClasses: builder.query<
      GetCohortCoursesFirstClassesTransformResponse,
      { code: string; studentId: string }
    >({
      query: ({ code, studentId }) => ({
        document: gql`
          query CohortCoursesFirstClasses($code: String, $studentId: String) {
            cohorts(where: { code: { _eq: $code } }) {
              _products_cohorts(
                where: {
                  product: { cohort_integrants: { id: { _eq: $studentId } } }
                }
              ) {
                product {
                  _product_courses(order_by: { course: { order: asc } }) {
                    course {
                      id
                      classes(limit: 1, order_by: { order: asc }) {
                        cohort_classes(
                          where: { cohort: { code: { _eq: $code } } }
                        ) {
                          date
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          code,
          studentId,
        },
      }),
      transformResponse: (data: GetCohortCoursesFirstClassesResponse) =>
        data.cohorts[0],
    }),
    getCohortClassesByCourse: builder.query<
      GetCohortClassesByCourseResponse,
      { code: string; courseId: string; studentId: string }
    >({
      query: ({ code, courseId, studentId }) => ({
        document: gql`
          query CohortClassesByCourse(
            $code: String
            $courseId: String
            $studentId: String
          ) {
            cohort_classes(
              where: {
                cohort: { code: { _eq: $code } }
                class: { course: { id: { _eq: $courseId } } }
              }
              order_by: { class: { order: asc } }
            ) {
              id
              status
              date
              isAsync
              class {
                id
                name
                course {
                  isAsync
                }
                exercises {
                  id
                  name
                  users_assignments(where: { studentId: { _eq: $studentId } }) {
                    status
                    id
                  }
                }
              }
              student_attendances(where: { studentId: { _eq: $studentId } }) {
                status
              }
              cohort {
                type
              }
            }
          }
        `,
        variables: {
          code,
          courseId,
          studentId,
        },
      }),
      providesTags: ['Class'],
    }),
    getCohortNextClass: builder.query<
      GetCohortNextClassTransformResponse,
      string
    >({
      query: code => ({
        document: gql`
          query CohortNextClass($code: String) {
            cohort_classes(
              where: {
                cohort: { code: { _eq: $code } }
                status: { _eq: "PENDIENTE" }
              }
              order_by: { class: { order: asc, course: { order: asc } } }
              limit: 1
            ) {
              id
              class {
                name
                description
              }
            }
          }
        `,
        variables: {
          code,
        },
      }),
      transformResponse: (data: GetCohortNextClassResponse) =>
        data.cohort_classes[0],
    }),
    getCohortNextAssignmentByStudent: builder.query<
      GetCohortNextAssignmentByStudentTransformResponse,
      { studentId: string; code: string }
    >({
      query: ({ studentId, code }) => ({
        document: gql`
          query CohortNextAssignmentByStudent(
            $studentId: String
            $code: String
          ) {
            users_assignments(
              where: {
                studentId: { _eq: $studentId }
                status: { _eq: "PENDIENTE" }
                exercise: { isIntegrative: { _eq: false } }
              }
              order_by: {
                exercise: { class: { course: { order: asc }, order: asc } }
              }
              limit: 1
            ) {
              id
              exercise {
                id
                name
                class {
                  course {
                    id
                    name
                  }
                  cohort_classes(where: { cohort: { code: { _eq: $code } } }) {
                    date
                  }
                }
              }
            }
          }
        `,
        variables: {
          studentId,
          code,
        },
      }),
      providesTags: ['Assignment'],
      transformResponse: (data: GetCohortNextAssignmentByStudentResponse) =>
        data.users_assignments[0],
    }),
    getAmountOfSubmittedAssignments: builder.query<
      GetAmountOfSubmittedAssignmentsTransformResponse,
      { code: string; exerciseId: string }
    >({
      query: ({ code, exerciseId }) => ({
        document: gql`
          query AmountOfSubmittedAssignment(
            $code: String
            $exerciseId: String
          ) {
            users_assignments(
              where: {
                cohort: { code: { _eq: $code } }
                exerciseId: { _eq: $exerciseId }
              }
            ) {
              id
            }
            users_assignments_aggregate(
              where: {
                cohort: { code: { _eq: $code } }
                exerciseId: { _eq: $exerciseId }
                _or: [
                  { status: { _eq: "APROBADA" } }
                  { status: { _eq: "ENTREGADA" } }
                  { status: { _eq: "EN_REVISION" } }
                ]
              }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: {
          code,
          exerciseId,
        },
      }),
      providesTags: ['Assignment'],
      transformResponse: (data: GetAmountOfSubmittedAssignmentsResponse) => ({
        total: data.users_assignments.length,
        submitted: data.users_assignments_aggregate.aggregate.count,
      }),
    }),
    getCohortAnnouncement: builder.query<
      GetCohortAnnouncementTransformResponse,
      { code: string }
    >({
      query: ({ code }) => ({
        document: gql`
          query GetCohortAnnouncement($code: String_comparison_exp) {
            cohorts(where: { code: $code }) {
              announcements {
                id
                title
                content
                url
              }
            }
          }
        `,
        variables: {
          code: {
            _eq: code,
          },
        },
      }),
      transformResponse: (data: GetCohortAnnouncementResponse) =>
        data.cohorts[0].announcements[0],
    }),
    getCohortZoomId: builder.query<
      GetCohortZoomIdTransformResponse,
      { code: string }
    >({
      query: ({ code }) => ({
        document: gql`
          query GetCohortZoomId($code: String_comparison_exp) {
            cohorts(where: { code: $code }) {
              zoomId
            }
          }
        `,
        variables: {
          code: {
            _eq: code,
          },
        },
      }),
      transformResponse: (data: GetCohortZoomIdResponse) => data.cohorts[0],
    }),
    getCohortUserRole: builder.query<
      GetCohortUserRoleTransformResponse,
      { code: string; userId: string }
    >({
      query: ({ code, userId }) => ({
        document: gql`
          query CohortUserRole($userId: String, $code: String) {
            cohort_integrants(
              where: {
                cohort: { code: { _eq: $code } }
                userId: { _eq: $userId }
              }
            ) {
              role
            }
          }
        `,
        variables: {
          code,
          userId,
        },
      }),
      transformResponse: (data: GetCohortUserRoleResponse) =>
        data.cohort_integrants[0],
    }),
    getStudentSubscription: builder.query<
      GetStudentSubscriptionTransformResponse,
      { studentId: string }
    >({
      query: ({ studentId }) => ({
        document: gql`
          query StudentSubscriptionQuery($studentId: String) {
            subscriptions(where: { studentId: { _eq: $studentId } }) {
              status
            }
            duos(where: { studentId: { _eq: $studentId } }) {
              subscription {
                status
              }
            }
          }
        `,
        variables: {
          studentId,
        },
      }),
      transformResponse: (data: GetStudentSubscriptionResponse) => {
        const sub =
          data.subscriptions.length > 0
            ? data.subscriptions[0]
            : data.duos.length > 0
              ? data.duos[0].subscription
              : undefined

        return sub
      },
    }),
    getCohort: builder.query<GetCohortTransformResponse, { id: string }>({
      query: ({ id }) => ({
        document: gql`
          query GetCohort($id: String) {
            cohorts(where: { id: { _eq: $id } }) {
              id
              code
              days
              startDate
              type
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (data: GetCohortResponse) => data.cohorts.at(0),
    }),
    getCohortClassByClassID: builder.query<
      GetCohortClassByClassIDTransformResponse,
      { classId: string; studentId: string }
    >({
      query: ({ classId, studentId }) => ({
        document: gql`
          query CohortClassByClassID($classId: String, $studentId: String) {
            cohort_classes(where: { id: { _eq: $classId } }) {
              summary
              vimeoId
              student_attendances(where: { studentId: { _eq: $studentId } }) {
                status
                studentId
              }
              class {
                challenge
                resources
                name
                description
                courseId
                course {
                  isAsync
                }
                exercises {
                  users_assignments(where: { studentId: { _eq: $studentId } }) {
                    id
                  }
                }
              }
              cohort {
                type
              }
            }
          }
        `,
        variables: {
          classId,
          studentId,
        },
      }),
      transformResponse: (data: GetCohortClassByClassIDResponse) => {
        const _class = data.cohort_classes[0]

        return {
          summary: _class.summary || 'Resumen no disponible',
          vimeoId: _class.vimeoId || '',
          challenge: _class.class.challenge || 'Challenge no disponible',
          className: _class.class.name || 'Clase no disponible',
          description: _class.class.description || 'Descripción no disponible',
          courseId: _class.class.courseId || '',
          courseIsAsync: _class.class.course.isAsync,
          assignmentId:
            _class.class.exercises[0]?.users_assignments[0]?.id || '',
          resources: _class.class.resources || ['Recursos no disponibles'],
          attendance: _class.student_attendances[0]?.status || null,
          cohortType: _class.cohort.type || '',
        }
      },
    }),
  }),
})

export const {
  useGetCohortCoursesQuery,
  useGetCohortCoursesFirstClassesQuery,
  useGetCohortClassesByCourseQuery,
  useGetCohortNextClassQuery,
  useGetCohortNextAssignmentByStudentQuery,
  useGetAmountOfSubmittedAssignmentsQuery,
  useGetCohortAnnouncementQuery,
  useGetCohortZoomIdQuery,
  useGetCohortUserRoleQuery,
  useGetStudentSubscriptionQuery,
  useGetCohortQuery,
  useGetCohortClassByClassIDQuery,
  usePrefetch: useCohortPrefetch,
} = cohortApi
