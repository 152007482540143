import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HStack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'
import { motion } from 'framer-motion'

import { CardContainer } from '../../shared/components/ClassCardContainer'
import { ClassCardContent } from '../../shared/components/ClassCardContent'
import {
  assignmentIsDisable,
  slugifyCourseName,
  formatStatus,
} from '../../shared/utils'

import { ASSIGNMENT_STATUS_COLOR } from '@/shared/constants'
import { AssignmentStatus } from '@/shared/enums'
import { useAssignmentPrefetch } from '@/education/assignments/services/assignment.service'
import { useExercisePrefetch } from '@/education/assignments/services/exercise.service'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { AmountOfSubmittedAssignments } from '@/education/shared/components/AmountOfSubmittedAssignments'

type SubClassCardProps = {
  subClasses: {
    /** @description id de la asignación o entrega*/
    id?: string

    name: string
    status?: AssignmentStatus
    date?: Date
    isGenerated?: boolean
  }[]
}

export const SubClassCard = ({ subClasses }: SubClassCardProps) => {
  const { state } = useLocation()
  const { code } = useParams()
  const navigate = useNavigate()

  const { isStaff } = useAppSelector(sessionState)

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )

  const prefetchExerciseDetails = useExercisePrefetch('getExerciseDetails')
  return (
    <>
      {subClasses.map(subClass => (
        <CardContainer
          key={subClass.id ?? subClass.name}
          as={motion.div}
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: {
              opacity: 1,
              transition: {
                delay: 0.3,
              },
            },
            collapsed: { opacity: 0 },
          }}
          w='90%'
          cursor='auto !important'
        >
          {isStaff ? (
            <ClassCardContent
              heading={subClass.name}
              textStyles={{ color: '#FFB800' }}
            >
              <HStack spacing={5}>
                <AmountOfSubmittedAssignments exerciseId={subClass.id!} />
                <Button
                  size='sm'
                  fontSize='sm'
                  borderRadius={30}
                  paddingInline={5}
                  onClick={() =>
                    navigate(`entregas/entrega/${subClass.id}`, { state })
                  }
                  onMouseEnter={() =>
                    prefetchExerciseDetails({
                      exerciseId: subClass.id!,
                      code: code!,
                    })
                  }
                >
                  Corregir
                </Button>
              </HStack>
            </ClassCardContent>
          ) : (
            <ClassCardContent heading={subClass.name}>
              <HStack gap={3} divider={<span>|</span>}>
                {assignmentIsDisable(subClass.date!) ||
                !subClass.isGenerated ? (
                  <Text fontSize='xs'> Entrega no disponible</Text>
                ) : (
                  <Text
                    color={
                      subClass?.status
                        ? ASSIGNMENT_STATUS_COLOR[subClass?.status]
                        : 'nucba.primary'
                    }
                    fontSize='xs'
                  >
                    ENTREGA {formatStatus(subClass?.status || '')}
                  </Text>
                )}

                <Button
                  size='sm'
                  fontSize='sm'
                  borderRadius={30}
                  paddingInline={5}
                  isDisabled={
                    assignmentIsDisable(subClass.date!) || !subClass.isGenerated
                  }
                  onClick={() =>
                    navigate(
                      `/camada/${code}/curso/${slugifyCourseName(state.courseName)}/asignacion/${subClass.id}`,
                      {
                        state: { ...state, fromApplication: true },
                      },
                    )
                  }
                  onMouseEnter={() =>
                    prefetchAssignmentDetails({
                      assignmentId: subClass.id!,
                      code: code!,
                    })
                  }
                >
                  Ver entrega
                </Button>
              </HStack>
            </ClassCardContent>
          )}
        </CardContainer>
      ))}
    </>
  )
}
